<template>
  <div>
    <el-card style="margin-top: 10px">
      <el-button type="primary" @click="dialogVisiblexui = true"
        >新增合作伙伴</el-button
      >
      <el-table
        :data="list"
        style="width: 100%"
        @sort-change="sortChange"
        border
        stripe
        :header-cell-style="rowClass"
      >
        <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="name" label="名称" width="180">
        </el-table-column>
        <el-table-column prop="linkUrl" label="链接地址">
          <template slot-scope="ew">
            <a :href="ew.row.linkUrl" target="“_blank”">{{ ew.row.linkUrl }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="hits" label="点击量"> </el-table-column>
        <el-table-column prop="logo" label="logo">
          <template slot-scope="ew">
            <img
              :src="baseurl + yu + ew.row.logo"
              alt=""
              style="width: 150px"
            />
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="时间" sortable="custom">
        </el-table-column>
        <el-table-column prop="orderNum" label="排序" sortable="custom">
          <template #header>
            <span>排序</span>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                :ref="'INPUTFOCUS' + $index"
                v-model="row.orderNum"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row)"
              />
            </div>
            <span
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('system_partner_order') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('system_partner_order') != -1 &&
                  showinput(row, 'INPUTFOCUS' + $index)
              "
            >
              {{ row.orderNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row.id)"
              class="skyblue"
              v-show="$store.state.btn_if.indexOf('system_partner_edit') != -1"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="open(scope.row.id)"
              class="red"
              v-show="
                $store.state.btn_if.indexOf('system_partner_delete') != -1
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPage"
        :total="total"
        :page-size="pagesize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      title="新增合作伙伴"
      :visible.sync="dialogVisiblexui"
      width="50%"
    >
      <el-form ref="form" :model="form_1" label-width="120px">
        <el-form-item label="名称">
          <el-input v-model="form_1.name"></el-input>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form_1.linkUrl"></el-input>
        </el-form-item>
        <el-form-item label="logo">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            :headers="header"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img
              v-if="form_1.logo"
              :src="baseurl + yu + form_1.logo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblexui = false">取 消</el-button>
        <el-button type="primary" @click="addedit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改合作伙伴" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-width="120px">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input v-model="form.linkUrl"></el-input>
        </el-form-item>
        <el-form-item label="logo">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
            :data="action"
          >
            <img
              v-if="form.logo"
              :src="baseurl + yu + form.logo"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      btn: true,
      currentPage: 1,
      pagesize: 10,
      action: {
        action: 'system'
      },
      list: [],
      order: '',
      form: {
        id: '',
        name: '',
        linkUrl: '',
        logo: ''
      },
      form_1: {
        name: '',
        linkUrl: '',
        logo: ''
      },
      header: {
        Token: sessionStorage.getItem('token')
      },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false,
      paiform: {}
    }
  },
  methods: {
    async handleInputBlur(row) {
      this.paiform = row
      try {
        const { data: res } = await this.$http.post(
          '/admin/Partner/edit',
          this.paiform
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          //  row.pai=false
          //  this.tableData=[...this.tableData]
          this.getlist()
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        // this.$message.error(e)
      }
      console.log(this.tableData)
    },
    showinput(row, refName) {
      console.log(row, refName, this.$refs[refName])
      row.pai = true
      this.list = [...this.list]
      setTimeout(() => {
        $('.id input').focus()
      }, 0)
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'partner_order_num'
        } else {
          s = 'partner_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    getlist() {
      // console.log(this.pagesize );
      // console.log( this.currentPage  );
      this.$http
        .get('/admin/Partner/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.total = res.data.totalCount
            console.log(this.list)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    handleAvatarSuccess(res, file) {
      this.form_1.logo = res.data.url
      console.log(file)
      // this.form.banner = URL.createObjectURL(file.raw)
    },
    handleAvatarSuccess_1(res, file) {
      this.form.logo = res.data.url
      console.log(file)
      // this.form.banner = URL.createObjectURL(file.raw)
    },
    edit(e) {
      this.form.id = e
      this.dialogVisible = true
      this.$http.get('/admin/Partner/getById?id=' + e).then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.form = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/Partner/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisible = false
              this.getlist()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/Partner/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    addedit() {
      this.$http
        .post('/admin/Partner/add', this.form_1)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.dialogVisiblexui = false
            // this.getlist()
            location.reload()
          } else {
            this.$message.error(res.message)
          }
        })
    }
  },
  created() {
    this.getlist()
    sessionStorage.setItem('action', 'system')
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: auto;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
</style>
